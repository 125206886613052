import React from 'react';
import './App.scss';
import Routes from './Routes'; // Import your Routes component

const App = () => (
  <div className="app">
    <Routes /> {/* Render your Routes component */}
  </div>
);

export default App;
