import React from 'react';
import { motion } from 'framer-motion';
import bgWhite from '../../assets/bgWhite.png'; // Import the background image
import { images } from '../../constants';
import './ProjectDetailsHeader.scss'; // Import your existing styles

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const getImageForTechnology = (technology) => {
  const lowercasedTechnology = technology.toLowerCase();

  switch (lowercasedTechnology) {
    case 'flutter':
      return images.flutter;
    case 'git':
      return images.git;
    case 'mobile2':
      return images.mobile2;
    case 'next':
      return images.next;
    case 'node':
      return images.node;
    case 'react':
      return images.react;
    case 'reactnative':
      return images.reactNative;
    case 'redux':
      return images.redux;
    case 'sanity':
      return images.sanity;
    case 'sass':
      return images.sass;
    case 'syncfusion':
      return images.syncfusion;
    case 'typescript':
      return images.typescript;
    // Add more cases for other technologies as needed
    default:
      return ''; // Return a default image or handle the case when technology is not recognized
  }
};

const ProjectDetailsHeader = ({ title, description, titleStyle, descriptionStyle, technologies }) => {
  // Split the title text based on the colon
  const [spanText, restText] = title.split(':');

  const headerStyle = {
    backgroundImage: `url(${bgWhite})`, // Set the background image
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover', // Adjust as needed
    // Other background-related styles here
  };

  // console.log('Technologies:', technologies);

  return (
    <div className="app__header app__flex" style={headerStyle}>
      <h2 className="head-text" style={titleStyle}>
        <span>{spanText && `${spanText.trim()} :`}</span> {restText && restText.trim()}
      </h2>
      <p className="app__work-content app__flex" style={descriptionStyle}>
        {description}
      </p>
      {/* Add more header content as needed */}

      <motion.img
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        whileInView={{ scale: [0, 1] }}
        transition={{ duration: 1, ease: 'easeInOut' }}
        src={images.circle}
        alt="profile_circle"
        className="overlay_circle"
      />

      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        variants={scaleVariants}
        whileInView="whileInView"
        className="app__header-circles"
      >
        {technologies.map((tech, index) => (
          <div className="circle-cmp app__flex" key={index}>
            <img src={getImageForTechnology(tech)} alt={`${tech}_image`} />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default ProjectDetailsHeader;
