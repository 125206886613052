import React, { useState, useEffect } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import { AppWrap } from '../../wrapper';
import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut',
    },
  },
};

const Header = () => {
  const [animationStarted, setAnimationStarted] = useState(false);
  const tiltX = useMotionValue(0);
  const tiltY = useMotionValue(0);

  const handleMouseMove = (event) => {
    const image = event.currentTarget;
    const rect = image.getBoundingClientRect();

    const posX = event.clientX - rect.left; // Mouse position X relative to the image
    const posY = event.clientY - rect.top; // Mouse position Y relative to the image

    const centerX = rect.width / 2; // X coordinate of the image center
    const centerY = rect.height / 2; // Y coordinate of the image center

    const tiltAmount = 20; // Maximum tilt angle

    const tiltXValue = ((posX - centerX) / centerX) * tiltAmount; // Calculate the tilt on the X-axis
    const tiltYValue = ((posY - centerY) / centerY) * tiltAmount; // Calculate the tilt on the Y-axis

    tiltX.set(tiltXValue); // Update the tilt value on the X-axis
    tiltY.set(tiltYValue); // Update the tilt value on the Y-axis
  };

  const handleMouseLeave = () => {
    tiltX.set(0); // Reset the tilt value on the X-axis
    tiltY.set(0); // Reset the tilt value on the Y-axis
  };

  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.app__header');

      if (element) {
        const rect = element.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;

        setAnimationStarted(isVisible);

        if (!isVisible || window.innerWidth <= 1200 || location.pathname === '/project-details') {
          element.classList.add('no-animation');
        } else {
          element.classList.remove('no-animation');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <div className="app__header app__flex">
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={animationStarted || window.innerWidth <= 1200 ? { x: 0, opacity: 1 } : { x: -100, opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello, I am</p>
              <h1 className="head-text">Jacob</h1>
            </div>
          </div>

          <div className="tag-cmp app__flex">
            <p className="p-text" style={{ textAlign: 'left' }}>
              I <strong>Develop</strong>
            </p>
            <p
              className="p-text"
              style={{
                textAlign: 'left',
                marginLeft: '20px',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              &emsp;
              <span style={{ fontSize: '90%', color: 'var(--secondary-color)' }}>
                &#10004;
              </span>{' '}
              Info Systems
              <br />
              &emsp;
              <span style={{ fontSize: '90%', color: 'var(--secondary-color)' }}>
                &#10004;
              </span>{' '}
              Desktop Applications
              <br />
              &emsp;
              <span style={{ fontSize: '90%', color: 'var(--secondary-color)' }}>
                &#10004;
              </span>{' '}
              Web Applications
            </p>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0 }}
        animate={animationStarted || window.innerWidth <= 1200 ? { opacity: 1, scale: 1.1 } : { opacity: 0, scale: 0 }}
        transition={{ duration: 0.8, delay: animationStarted ? 0.6 : 0 }}
        className="app__header-img"
        onAnimationStart={() => setAnimationStarted(true)} // Set animationStarted to true
      >
        {animationStarted && (
          <>
            <motion.img
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              whileInView={{ scale: [0, 1] }}
              transition={{ duration: 1, ease: 'easeInOut' }}
              src={images.circle}
              alt="profile_circle"
              className="overlay_circle"
            />

            <motion.img
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              whileHover={{ scale: 1.1, rotateY: 10 }}
              transition={{ duration: 0.8 }}
              src={images.profile}
              alt="profile_image"
              className="overlay_hover"
              style={{
                rotateX: tiltY,
                rotateY: tiltX,
                transition: 'transform 0.3s ease',
              }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            />
          </>
        )}
      </motion.div>

      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        variants={scaleVariants}
        whileInView="whileInView"
        className="app__header-circles"
      >
        <div className="circle-cmp app__flex">
          <img src={images.javascript} alt="profile_bg" />
        </div>
        <div className="circle-cmp app__flex">
          <img src={images.next} alt="profile_bg" />
        </div>
        <div className="circle-cmp app__flex">
          <img src={images.typescript} alt="profile_bg" />
        </div>
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, 'home');
