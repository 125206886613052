import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { HiChevronLeft } from 'react-icons/hi';
import { images } from '../../constants';
import './ProjectDetailsNavbar.scss';

const ProjectDetailsNavbar = ({ includeBackButton }) => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to handle navigating back
  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <nav className="app__navbar">
      {includeBackButton && ( // Conditionally render the Back button
        <div className="app__back-btn app__flex">
          <div className="app__flex" onClick={handleGoBack}>
            <HiChevronLeft />
            <span className="app__back-text">BACK</span>
          </div>
        </div>
      )}
      <div className="app__navbar-logo">
        <img src={images.logo} alt="logo" />
      </div>
    </nav>
  );
};

export default ProjectDetailsNavbar;
