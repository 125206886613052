import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Update the import statement
import HomePage from './pages/HomePage'; // Import your HomePage component
import ProjectDetails from './pages/ProjectDetails'; // Import your ProjectDetails component

const AppRoutes = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} /> {/* Define your homepage component */}
      <Route path="/project-details" element={<ProjectDetails />} />
      {/* Add more routes as needed */}
    </Routes>
  </Router>
);

export default AppRoutes;
