import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { ProjectDetailsNavbar } from '../components';
import { ProjectDetailsHeader } from '../container';
import comingSoonImage from '../assets/comingsoon.png'; // Replace with the actual path to your image

const ProjectDetails = () => {
  const location = useLocation();
  const { selectedProject } = location.state || {};
  const [readmeContent, setReadmeContent] = useState('');
  const [activeLinks, setActiveLinks] = useState(null);

  // Define the handleWorkLinks function
  const handleWorkLinks = (item) => {
    if (item === 'Visit Project') {
      window.open(selectedProject.projectLink, '_blank');
    } else if (item === 'View Code') {
      window.open(selectedProject.codeLink, '_blank');
    }
    // Set the activeLinks state based on the clicked item
    setActiveLinks(item);
  };

  useEffect(() => {
    if (selectedProject && selectedProject.codeLink) {
      const { codeLink } = selectedProject;
      // console.log('codeLink:', codeLink); // Log the codeLink for debugging
      const githubToken = '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';

      try {
        const url = new URL(codeLink);
        const pathnameSegments = url.pathname.split('/').filter(Boolean); // Remove empty segments
        const repo = pathnameSegments.pop(); // Get the last segment as the repo
        const owner = pathnameSegments.pop(); // Get the second-to-last segment as the owner

        // console.log('Owner:', owner); // Log the owner for debugging
        // console.log('Repo:', repo); // Log the repo for debugging

        if (owner && repo) {
          fetch(`https://api.github.com/repos/${owner}/${repo}/contents/README.md`, {
            headers: {
              Authorization: `token ${githubToken}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                // console.error('Fetch error:', response.statusText);
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then((data) => {
              // console.log('GitHub API Response:', data); // Add this line to check the response
              const decodedContent = atob(data.content);
              // console.log('Decoded Content:', decodedContent); // Add this line to check the decoded content
              setReadmeContent(decodedContent);
            })
            .catch((error) => console.error(error));
        }
      } catch (error) {
        // console.error('Error parsing GitHub URL:', error);
      }
    } else {
      // Handle projects without a GitHub link
      setReadmeContent(`![Coming Soon](${comingSoonImage})`);
    }
  }, [selectedProject]);

  // Custom rendering component for React Markdown
  const components = {
    img: ({ src, alt }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '25vh', // Adjust the height as needed
        }}
      >
        <img
          src={src}
          alt={alt}
          style={{ maxWidth: '40%', maxHeight: '40%', width: 'auto', height: 'auto' }}
        />
      </div>
    ),
  };

  return (
    <>
      <ProjectDetailsNavbar includeBackButton />
      <div>
        {selectedProject && (
          <>
            <div className="project-details-container">
              <ProjectDetailsHeader
                title={selectedProject.title}
                description={selectedProject.description}
                titleStyle={{
                  margin: '4rem',
                  maxWidth: '900px',
                }}
                descriptionStyle={{
                  margin: '6rem',
                  fontSize: '22px',
                  lineHeight: '2',
                  maxWidth: '900px',
                }}
                technologies={selectedProject.technologies}
              />
              {/* Section with Call-to-Action Buttons */}
              <section className="app__work-links">
                {['Visit Project', 'View Code'].map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleWorkLinks(item)}
                    className={`app__work-links-item app__flex p-text ${activeLinks === item ? 'item-active' : ''}`}
                  >
                    {item}
                  </div>
                ))}
              </section>
              <div className="readme-container">
                <ReactMarkdown components={components}>{readmeContent}</ReactMarkdown>
              </div>
            </div>
            <div className="extra-space" /> {/* Self-close the empty div */}
          </>
        )}
      </div>
    </>
  );
};

export default ProjectDetails;
