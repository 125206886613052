// HomePage.js
import React from 'react';
import { Navbar } from '../components';
import { About, Header, Work, Skills, Testimonial, Footer } from '../container'; // Import other components

const HomePage = () => (
  <div>
    <Navbar />
    <Header />
    <Work />
    <About />
    <Skills />
    <Testimonial />
    <Footer />
  </div>
);

export default HomePage;
