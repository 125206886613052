import sanityClient from '@sanity/client';

const contactFormClient = sanityClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-02-01',
  token: process.env.REACT_APP_SANITY_CONTACT_TOKEN, // Use a separate API token with write access
});

export default contactFormClient;
