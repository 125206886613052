import React from 'react';
import { createRoot } from 'react-dom'; // Import createRoot from react-dom

import App from './App';
import './index.css';

const root = document.getElementById('root');

const appRoot = createRoot(root); // Create a root for your application
appRoot.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
